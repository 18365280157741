* { 
  font-family: 'Arial', sans-serif; box-sizing: border-box; margin: 0; padding: 0; 
}
html, body{
  overscroll-behavior-y: none;
}
/* html, body { width: 480px; position: relative; overflow-x: hidden; box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px; } */
body{
  display: flex;
  justify-content: center;
}
html { margin-left: auto; margin-right: auto;}
.App { text-align: center; }
.width_full {
  width: 480px
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
::-webkit-scrollbar-track{
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: rgba(0,0,0,0.1); 
  /* background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%,rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%,transparent 75%, transparent) */
}


body{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  /* background-color: #D400B8 */
}

#root{
  width: 480px;
  position: relative;
  overflow-x: hidden;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

/* ==================================================== */
/* ======= "GLOBAL DEFAULT CUSTOMIZATION" STYLE ======= */
/* ==================================================== */
/* BK */
.bg-purple { background-color: #D400B8 }
.btn-primary { color: #fff; background-color: #D400B8; border-color: #D400B8 }
.btn-primary-white { color: #373b3d; background-color: #fff; border-color:rgba(0,0,0,0.05) }
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.275) !important;
  opacity: 1; /* Firefox */
}
.btn-primary:hover, .btn-primary-dark:hover, .btn-primary-white:hover { color: #fff; background-color: #D400B8; border-color:transparent }
.btn-primary:focus {
  color: #fff;
  background-color: #D400B8;
  border-color: #D400B8;
  box-shadow: none;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #FFF !important;
}
.accordion-button:not(.collapsed) {
  color: #FFF !important;
  background-color: transparent !important;
  box-shadow: none !important;
  border-bottom: 2px solid rgba(0,0,0,0.03) !important;
}
.accordion-toggle:after {
  content: "\e114" !important;   
  color: white !important;         /* adjust as needed */
}
.accordion-button {
  background-color: transparent !important;
  color: #FFF !important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem transparent !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

/* laundry */
.input-rounded { border-radius: 50px; overflow: hidden }
.border-top { border-top: solid 1px rgba(0,0,0,0.05) !important }
.border-bottom { border-bottom: solid 1px rgba(0,0,0,0.05) !important }
.btn-rounded { border-radius: 50px; overflow: hidden }
.input-password { -webkit-text-security: disc }
.cursor { cursor: pointer }
.no-border { border: none !important }
.bg-purple { background-color: #D400B8 }
.bg-purple-soft { background-color: #2980B9 }
.bg-purple-dark { background-color: #2B5775 }
.bg-purple-light { background-color: #69CCF6 }
.bg-white { background-color: #fff }
.bg-gray-light { background-color: #E8E8E8 }
.txt-green { color: #27ae60 !important; }
.txt-black { color: #505050 }
.txt-red { color: #e74c3c !important }
.txt-orange-light { color: #f39c12 }
.txt-orange-dark { color: #e67e22 !important; }
.txt-white { color: #fff }
.txt-purple { color: #2980B9 !important }
.txt-purple-dark { color: #2B5775 }
.txt-purple-light { color: #69CCF6 }
.txt-gray-dark { color: darkgrey; }
.txt-size16 { font-size: 16pt; }
.txt-size14 { font-size: 14pt; }
.txt-size12 { font-size: 12pt; }
.txt-size10 { font-size: 10pt; }
.txt-size9 { font-size: 9pt; }
.txt-size8 { font-size: 8pt; }
.mg-top3 { margin-top: 3px; }
.mg-top5 { margin-top: 5px; }
.mg-top10 { margin-top: 10px; }
.mg-top15 { margin-top: 15px; }
.mg-top20 { margin-top: 20px; }
.mg-top25 { margin-top: 25px; }
.mg-top30 { margin-top: 30px; }
.mg-right2 { margin-right: 2px; }
.mg-right5 { margin-right: 5px; }
.mg-right10 { margin-right: 10px; }
.mg-right15 { margin-right: 15px; }
.mg-right20 { margin-right: 20px; }
.mg-right25 { margin-right: 25px; }
.mg-right30 { margin-right: 30px; }
.mg-left5 { margin-left: 5px; }
.mg-left10 { margin-left: 10px; }
.mg-left15 { margin-left: 15px; }
.mg-left20 { margin-left: 20px; }
.mg-left25 { margin-left: 25px; }
.mg-left30 { margin-left: 30px; }
.mg-btm10 { margin-bottom: 10px; }
.mg-btm15 { margin-bottom: 15px; }
.mg-btm20 { margin-bottom: 20px; }
.mg-btm25 { margin-bottom: 25px; }
.mg-btm30 { margin-bottom: 30px; }
.pd-top5 { padding-top: 5px; }
.pd-top10 { padding-top: 10px; }
.pd-top15 { padding-top: 15px; }
.pd-top20 { padding-top: 20px; }
.pd-top25 { padding-top: 25px; }
.pd-top30 { padding-top: 30px; }
.pd-btm10 { padding-bottom: 10px; }
.pd-btm15 { padding-bottom: 15px; }
.pd-btm20 { padding-bottom: 20px; }
.pd-btm25 { padding-bottom: 25px; }
.pd-btm30 { padding-bottom: 30px; }
.pd-right10 { padding-right: 10px; }
.pd-right15 { padding-right: 15px; }
.pd-right20 { padding-right: 20px; }
.pd-right25 { padding-right: 25px; }
.pd-right30 { padding-right: 30px; }
.pd-left10 { padding-left: 10px; }
.pd-left15 { padding-left: 15px; }
.pd-left20 { padding-left: 20px; }
.pd-left25 { padding-left: 25px; }
.pd-left30 { padding-left: 30px; }
.btn-cancel { color: #fff; background-color: #e74c3c; border-color: #e74c3c; font-weight: bold; }
.btn-cancel:hover { color: #fff; background-color: #c0392b; border-color: #c0392b }
.btn-cancel-outline { color: #e74c3c; background-color: #fff; border: solid 1px #e74c3c }
.btn-cancel-outline:hover { color: #fff; background-color: #e74c3c; border: solid 1px #e74c3c }
.btn-warning-outline { color: #f39c12; background-color: #fff; border: solid 1px #f39c12 }
.btn-warning-outline:hover { color: #fff; background-color: #f39c12; border: solid 1px #f39c12 }
.btn-primary-dark { color: #fff; background-color: #2980B9; border-color: #2980B9 }
/* .btn-primary:hover, .btn-primary-dark:hover, .btn-primary-white:hover { color: #fff; background-color: #2B5775; border-color:transparent } */
.wd-38 { width: 38px; text-align: center }
.btn-add { width: 180px; height: 36px; color: #fff; background-color: #D400B8; border: none; }
.btn-add:hover { background-color: #2B5775 }
.btn-delete-outline { font-size: 14px; color: #e74c3c; background-color: #fff; border: solid 1px #e74c3c }
.btn-delete-outline:hover { color: #fff; background-color: #e74c3c; border: solid 1px #e74c3c }
.btn-edit-outline { font-size: 14px; color: #f39c12; background-color: #fff; border: solid 1px #f39c12 }
.btn-edit-outline:hover { color: #fff; background-color: #f39c12; border: solid 1px #f39c12 }
.btn-detail-outline { font-size: 14px; color: #D400B8; background-color: #fff; border: solid 1px #D400B8 }
.btn-detail-outline:hover { color: #fff; background-color: #D400B8; border: solid 1px #D400B8 }
.btn-success-outline { font-size: 14px; color: #27ae60; background-color: #fff; border: solid 1px #27ae60 }
.btn-success-outline:hover { color: #fff; background-color: #27ae60; border: solid 1px #27ae60 }
.btn-success { font-size: 14px; color: #fff; background-color: #1abc9c; border: solid 1px #1abc9c }
.btn-success:hover { color: #fff; background-color: #16a085; border: solid 1px #16a085 }
.btn-payment-outline { font-size: 14px; color: #e67e22; background-color: #fff; border: solid 1px #e67e22 }
.btn-payment-outline:hover { color: #fff; background-color: #e67e22; border: solid 1px #e67e22 }
.info-success { display: flex; align-items: center; justify-content: center; border: solid 1px rgba(26, 188, 156, 0.3); border-radius: 3px; padding: 7.5px 10px; background-color: rgba(26, 188, 156, 0.2); font-size: 14px; text-align: left; color: rgba(26, 188, 156, 1.0) }
.info-success .message { flex: 1 }
.info-warning { display: flex; align-items: center; justify-content: center; border: solid 1px rgba(230, 126, 34, 0.3); border-radius: 3px; padding: 7.5px 10px; background-color: rgba(230, 126, 34, 0.2); font-size: 14px; text-align: left; color: rgba(230, 126, 34, 1.0) }
.info-warning .message { flex: 1 }
.no-background { background-color: transparent; }
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: rgba(0,0,0,0.2);
  border-color: rgba(0,0,0,0.02);
}

/* ==================================================== */
/* ======= "REACT BOOTSTRAP CUSTOMIZATION" STYLE ====== */
/* ==================================================== */
.modal-content { border:none; border-radius: 15px; background-color: unset; }
.modal-header { border:none }
.modal-header .title { font-size: 16px; font-weight: bold; color: #505050; }
.modal-header .icon { color: #e74c3c; cursor: pointer }
.modal-header .icon:hover { color: #c0392b }
.modal-body { text-align: center; }
.modal-body .image { width: 90px; height: 90px; object-fit: cover }
.modal-50w { max-width: 50% }
.modal-60w { max-width: 60% }
.modal-80w { max-width: 80% }
.modal-90w { max-width: 90% }
.modal-70h { height: 70vh }
.modal-70h .scollable { height: 62vh; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll }
.modal-70h .scollable::-webkit-scrollbar { display: none; }
.modal-75h { height: 75vh }
.modal-80h { height: 80vh }
.modal-lg { max-width: 80% }
.modal-footer { border:none; justify-content: center }

/* =================================== */
/* ======= "SPLASH" VIEW STYLE ======= */
/* =================================== */
.Splash { height: 100vh; padding: 28vh 0; text-align: center; background-color: #D400B8 }
.Splash-img { height: 25vh; animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards }
.Splash-title { color: #fff; margin-top: 30px; font-weight: bold; animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards }
.Splash-subtitle { margin-right: 60px; margin-left: 60px; color: #fff; margin-top: 15px; font-size: 18px; animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards }
.Splash-loading { position: absolute; bottom: 10vh; height: 3vh }

/* =================================== */
/* ======= "LOGIN" VIEW STYLE ======= */
/* =================================== */

/* BK */
.Login { height: 100vh; height: calc(var(--vh, 1vh) * 100); padding: 18vh 0; text-align: center; background-color: #D400B8 }
.Login-img { width: 80%; animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards }
.Login-title { color: #fff; margin-top: 30px; font-weight: bold; animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards }
.Login-subtitle { margin-right: 60px; margin-left: 60px; color: #fff; margin-top: 15px; font-size: 18px; animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards }
.Login-input-container { border-radius: 30px 30px 0px 0px; position: absolute; bottom: 0vh; padding-bottom: 30px; padding-top: 30px; width: 100%; padding-left: 2vw; padding-right: 2vw; animation: fadeInAnimation ease 3s; animation-iteration-count: 1; animation-fill-mode: forwards; background-color: #950082 }

/* ===================================== */
/* ======= "MY CONTAINERS" STYLE ======= */
/* ===================================== */
.MyContainers { display: flex; flex-direction: row; height: 100vh }
.MyContainers-right { margin-left: 260px; background-color: aliceblue; width: calc(100% - 260px); right: 0; z-index: 1; border-top-left-radius: 25px; border-bottom-left-radius: 25px; padding: 0 0 0 0; overflow: hidden; }
.MyContainers-right-toolbar { height: 80px; display: flex; align-items: center; }
.MyContainers-right-toolbar .left { display: flex; flex-direction: row; padding-left: 20px; }
.MyContainers-right-toolbar .left .image { height: 45px; width: 45px; border-radius: 7.5px; object-fit: cover; margin-right: 15px }
.MyContainers-right-toolbar .left .desc { padding-top: 2px; }
.MyContainers-right-toolbar .left .desc .title { font-size: 16px; font-weight: bold }
.MyContainers-right-toolbar .left .desc .subtitle { font-size: 14px }
.MyContainers-right-toolbar .action { text-align: right; padding-right: 20px }
.MyContainers-right-contents { height: calc(100vh - 80px); /* overflow-x: scroll; */ }

/* ============================================ */
/* ======= "DASHBOARD" VIEW STYLE ============= */
/* ============================================ */

.Dashboard { padding: 0 20px; }
.Closereg { padding: 0 20px; }
.Expense { padding: 0 20px; }
.Report { padding: 0 20px; }
.Guide { padding: 20px; }
.Customer { padding: 0 20px; overflow: hidden; }
.Services { padding: 0 20px; }
.Merchant { padding: 0 20px; }
.Promo { padding: 0 20px; }

/* ============================================ */
/* ======= "POS" VIEW STYLE =================== */
/* ============================================ */
.Pos { padding: 0 0 0 20px; display: flex; height: 100vh }
.Pos .left { flex: 1; background-color: aliceblue }
.Pos .left .product { -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll; height: calc(80vh - 20px); margin-top: 20px }
.Pos .left .product::-webkit-scrollbar { display: none; }
.Pos .left .product .cards { display: flex; flex-wrap: wrap; padding-right: 3px }
.Pos .left .product .cards .item { width: calc((100% - 63px) / 4); border-radius: 15px; overflow: hidden; height: 260px; margin-bottom: 18px; margin-right: 15px;  text-align: center; color: #505050; background-color: rgba(255,255,255,0.4); display: flex; flex-direction: column; align-items: center; justify-content: center }
.Pos .left .product .cards .item .image { width: 70px; height: 70px; object-fit: contain }
.Pos .left .product .cards .item .image.no-png { object-fit: cover; border-radius: 35px }
.Pos .left .product .cards .item .name { font-weight: bold; font-size: 14px; margin-top: 15px }
.Pos .left .product .cards .item .price { font-weight: bold; font-size: 15px; margin-top: 5px; margin-bottom: 15px }
.Pos .left .product .cards .item .note { font-size: 12px }
.Pos .left .product .cards .item:hover { background-color: #FFF }
/* .Pos .left .product .cards .item .action {} */
.Pos .right { width: 450px; height: 100%; background-color: #FFF; overflow: hidden }
.Pos .right .stores { display: none; align-items: center; padding: 15px 15px 5px 15px }
.Pos .right .stores .image { width: 45px; height: 45px; object-fit: cover; border-radius: 7.5px }
.Pos .right .stores .info { flex: 1; padding-left: 12px; padding-right: 15px; color: #505050 }
.Pos .right .stores .info .title { font-size: 16px; font-weight: bold; }
.Pos .right .stores .info .subtitle { font-size: 14px; }
.Pos .right .stores .action { width: 20px; height: 20px; cursor: pointer }
.Pos .right .customers { display: flex; align-items: center; padding: 15px; position: relative; }
.Pos .right .customers .image { width: 45px; height: 45px; object-fit: cover; border-radius: 7.5px; align-items: center; justify-content: center; display: flex; background-color: #EFEFEF; color: rgba(80,80,80,0.3); font-size: 18px; font-weight: bold }
.Pos .right .customers .info { flex: 1; padding-left: 12px; padding-right: 15px; color: #505050 }
.Pos .right .customers .info .name { font-size: 16px; font-weight: bold; }
.Pos .right .customers .info .mobilephone { font-size: 14px; }
.Pos .right .customers .action { cursor: pointer; }
.Pos .right .carts .header { display: flex; align-items: center }
.Pos .right .carts .action { display: none; align-items: center; padding-right: 15px }
.Pos .right .carts .lists { margin-top: 5px; background-color: #EDEDED; height: 52vh; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll; padding: 15px 15px 5px 15px; border-top: solid 1px #ecf0f1; border-bottom: solid 1px #ecf0f1 }
.Pos .right .carts .lists::-webkit-scrollbar { display: none; }
.Pos .right .carts .lists .cart-item { background-color: #fff; border-radius: 5px; padding: 10px 10px; position: relative; margin-bottom: 10px }
.Pos .right .carts .lists .cart-item .info { display: flex }
.Pos .right .carts .lists .cart-item .info .image { width: 40px; height: 40px; object-fit: contain }
.Pos .right .carts .lists .cart-item .info .image.no-png { object-fit: cover; border-radius: 5px }
.Pos .right .carts .lists .cart-item .info .image.no-img { background-color: #EFEFEF; display: flex; align-items: center; justify-content: center; border-radius: 5px; color: #999; font-size: 14px; font-weight: bold }
.Pos .right .carts .lists .cart-item .info .product { flex: 1; padding-left: 10px; align-items: center }
.Pos .right .carts .lists .cart-item .info .product .name { font-weight: bold; font-size: 14px }
.Pos .right .carts .lists .cart-item .info .product .price { font-size: 12px; font-weight: bold; color: rgba(80,80,80,0.7); }
.Pos .right .carts .lists .cart-item .info .actions { text-align: right }
.Pos .right .carts .lists .cart-item .info .actions .button { display: flex; justify-content: right;}
.Pos .right .carts .lists .cart-item .info .actions .price { font-size: 14px; font-weight: bold }
.Pos .right .carts .lists .cart-item .desc { font-size: 12px; color: #505050; margin-top: 8px }
.Pos .right .summary .lists { display: flex; align-items: center; justify-content: space-between; padding: 10px 15px; font-size: 14px; border-bottom: solid 1px rgba(0,0,0,0.03) }
.Pos .right .summary .lists .title { flex: 1 }
.Pos .right .summary .lists .value { font-weight: bold }
.Pos .right .summary .lists .value .link { color: #D400B8; cursor: pointer; font-weight: normal }
.Pos .right .summary .lists .value .select { font-size: 14px; color: rgba(80,80,80,1.0); border: none; border: none; }
.Pos .right .summary .lists .value .select:focus { outline: none; border:none }
.Pos .right .summary .resume { display: flex; align-items: center; padding: 12px 15px 0 15px }
.Pos .right .summary .resume .info { flex: 1; text-align: right; color: #505050 }
.Pos .right .summary .resume .info .title { font-size: 18px; font-weight: bolder; color: #2980b9 }
.Pos .right .summary .resume .info .subtitle { font-size: 14px }

/* ============================================ */
/* ======= "TRANSACTION" VIEW STYLE =========== */
/* ============================================ */
.Transaction { padding: 0 20px; overflow: hidden; }
.TransactionModal { color: #505050; display: flex; height: 102% }
.TransactionModal .left { width: 33.33%; border-right: solid 1px rgba(0,0,0,0.05); padding-right: 20px; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll }
.TransactionModal .left::-webkit-scrollbar { display: none; }
.TransactionModal .left .user { justify-content: space-between; display: flex; height: fit-content; align-items: center }
.TransactionModal .left .user .image { width: 42px; height: 42px; border-radius: 7px; overflow: hidden; object-fit: cover }
.TransactionModal .left .user .customer { text-align: left; flex: 1; padding-left: 12px; padding-right: 12px }
.TransactionModal .left .user .customer .name { font-size: 16px; font-weight: bold; }
.TransactionModal .left .user .customer .phone { font-size: 14px; }
.TransactionModal .left .user .transaction { text-align: right; }
.TransactionModal .left .user .transaction .inv { font-size: 14px; }
.TransactionModal .left .user .transaction .price { font-size: 16px; font-weight: bold; }
.TransactionModal .left .notes { text-align: left; padding: 10px 0; border-top: solid 1px rgba(0,0,0,0.03); }
.TransactionModal .left .notes.flex { display: flex; justify-content: space-between; align-items: center; }
.TransactionModal .left .notes .title { font-size: 12px; }
.TransactionModal .left .notes .subtitle { font-size: 15px; }
.TransactionModal .left .notes .link { font-size: 15px; color: #D400B8; text-decoration: underline; cursor: pointer; }
.TransactionModal .left .status { margin-top: 30px; }
.TransactionModal .center { width: 33.66%; padding-left: 20px; border-right: solid 1px rgba(0,0,0,0.05); padding-right: 5px }
.TransactionModal .center .item { height: 95%; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll }
.TransactionModal .center .item::-webkit-scrollbar { display: none; }
.TransactionModal .center .product { display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px }
.TransactionModal .center .product .image { width: 45px; height: 45px; border-radius: 7px; overflow: hidden; object-fit: cover; background-color: #D400B8; align-items: center; justify-content: center; display: flex; color: #fff; font-weight: bold }
.TransactionModal .center .product .info { text-align: left; flex: 1; padding-left: 12px; padding-right: 12px }
.TransactionModal .center .product .info .title { font-size: 14px; font-weight: bold; }
.TransactionModal .center .product .info .subtitle { font-size: 12px; }
.TransactionModal .center .product .price { text-align: right; padding-right: 15px }
.TransactionModal .center .product .price .title { font-size: 14px; font-weight: bold; }
.TransactionModal .center .product .price .subtitle { font-size: 12px; }
.TransactionModal .right { width: 33.00%; padding-left: 20px; padding-right: 5px; }
.TransactionModal .right .status { display: flex; justify-content: space-between; border-radius: 5px; margin-top: 20px; padding: 10px 15px; margin-bottom: 25px; }
.TransactionModal .right .status .desc { text-align: left; }
.TransactionModal .right .status .value { text-align: right; }
.TransactionModal .right .status .value.link { cursor: pointer; }
.TransactionModal .right .status-info { display: flex; justify-content: space-between; margin-top: 10px; padding: 0 2px; }
.TransactionModal .right .status-info .desc { font-size: 15px; text-align: left; }
.TransactionModal .right .status-info .value { font-size: 15px; text-align: right; }
.TransactionModalFooter { display: flex; align-items: center; width: 100%; justify-content: center }
.TransactionProces-Item { border: solid 1px #E8E8E8; background-color: #f7f7f7; margin: 5px 0 5px 0; padding: 10px 15px; border-radius: 10px; cursor: pointer; font-size: 14px; display: flex; justify-content: space-between; align-items: center }
.TransactionProces-Item i { color: #E8E8E8; }
.TransactionProces-Item i.active { color: #D400B8; }
.TransactionProces-Item.active { border-color: #D400B8; color: #D400B8; font-weight: bold; }
.TransactionProces-Item:hover { border-color: #D400B8; color: #D400B8; font-weight: bold; }

/* ============================================ */
/* ======= "ACCOUNT" VIEW STYLE =============== */
/* ============================================ */
.Account { padding: 20px; display: flex; color: #505050; overflow: hidden; }
.Account .profile { display: flex }
.Account .profile .left { border-radius: 15px; overflow: hidden; width: 270px; height: 270px; position: relative; }
.Account .profile .left .avatar { width: 270px; height: 270px; object-fit: cover }
.Account .profile .left .button { position: absolute; top: 10px; right: 20px; font-size: 35px; cursor: pointer; }
.Account .profile .right { padding-left: 20px; width: 500px; }
.Account .profile .right .name { font-size: 18pt; font-weight: bold }
.Account .profile .right .email { font-size: 12pt }
.Account .profile .right .address { font-size: 12pt }
.Account .profile .right .role { font-size: 11pt; color: #fff; background-color: #D400B8; width: 180px; align-items: center; justify-content: center; text-align: center; padding: 2.5px 7.5px; border-radius: 30px; margin-top: 10px; font-weight: 900; margin-bottom: 18px; }
.Account-menu { position: absolute; top: 390px; padding-left: 20px }
.Account-menu .menu { font-size: 14px; cursor: pointer; text-decoration: none; color: #D400B8; margin-bottom: 15px; display: flex; align-items: center; width: 270px; background-color: aliceblue; padding: 8px 15px; border-radius: 5px; border: solid 0.5px #D400B8; }
.Account-menu .menu a { font-size: 14px; cursor: pointer; text-decoration: none; color: #D400B8; display: flex; align-items: center; width: 270px }
.Account-menu .menu:hover { background-color: #D400B8; color: #fff; border: solid 0.5px #D400B8; }
.Account-menu .menu a:hover { background-color: #D400B8; color: #fff; border: solid 0.5px #D400B8; }
.Account-menu .menu .icon { display: none }
.Account-menu .menu .version { margin-left: 5px; }

/* ============================================ */
/* ======= "SEARCH" STYLE ===================== */
/* ============================================ */
.search-container { display: flex; width: 100%; align-items: center }
.search-container .left { margin-right: 15px; }
.search-container .center { width: 40%; }
.search-container .right { width: 46%; text-align-last: end; font-size: 14px; }

/* ============================================ */
/* ======= "TAB" STYLE ======================== */
/* ============================================ */
.tab-container { display: flex; width: 100%; align-items: center; padding-top: 5px; padding-bottom: 25px }
.tab-container .item { font-size: 11pt; color: rgba(80,80,80,0.4); cursor: pointer; padding: 10px 0px 0px 0px; margin-right: 20px }
.tab-container .item.active { color: rgba(80, 80, 80, 0.9); font-weight: 600; border-left: solid 1px rgba(0,0,0,0.1); border-right: solid 1px rgba(0,0,0,0.1); border-top: solid 1px rgba(0,0,0,0.1); border-top-left-radius: 10px; border-top-right-radius: 10px; padding: 10px 20px 0px 20px }

/* ============================================ */
/* ======= "COMPONENT INPUT FORM" STYLE ======= */
/* ============================================ */
.input-overlay { width: 100%; height: 100%; background-color: rgba(0,0,0,0.8); position: absolute; top: 0; left: 0; z-index: 2; animation: fadeInAnimation ease 2s; animation-iteration-count: 1; animation-fill-mode: forwards }
.input-container { z-index: 9999; height: 100vh; background-color: #fff; width: 33vw; position: absolute; top: 0; right: -33vw; transition: all 0.5s ease }
.input-container.active { right: 0; transition: all 0.5s ease }
.input-container .toolbar { height: 75px; padding: 12px 20px }
.input-container .toolbar .title { font-size: 14pt; font-weight: bold; color: #D400B8 }
.input-container .toolbar .subtitle { font-size: 11pt; color: #505050 }
.input-container .toolbar .close { position: absolute; top: 23px; right: 15px; font-size: 16pt; color: #e74c3c; cursor: pointer }
.input-container .content { height: calc(100vh - 140px); padding: 15px 20px 30px 20px; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll }
.input-container .content::-webkit-scrollbar { display: none; }
.input-container .content .label { font-size: 11pt; color: rgba(80,80,80,0.7); margin-top: 20px }
.input-container .content .required { font-size: 9pt; color: rgba(80,80,80,0.7); margin-top: 5px; display: flex; justify-content: space-between }
.input-container .content .required .true { color: #e74c3c; }
.input-container .content .required .false { color: #1ABC9C; }
.input-container .content .input { font-size: 12pt; color: rgba(80,80,80,1.0); width: 100%; border: none; border-bottom: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding-bottom: 7.5px }
.input-container .content .input:focus { outline: none; border-bottom: solid 1px #D400B8 }
.input-container .content .select { font-size: 12pt; color: rgba(80,80,80,1.0); width: 100%; border: none; border-bottom: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding-bottom: 7.5px }
.input-container .content .select:focus { outline: none; border-bottom: solid 1px #D400B8 }
.input-container .content .textarea { font-size: 12pt; color: rgba(80,80,80,1.0); width: 100%; border: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding: 7.5px; border-radius: 5px; resize: none }
.input-container .content .textarea:focus { outline: none; border: solid 1px #D400B8 }
.input-container .content .section { font-size: 16px; font-weight: bold; margin-top: 20px; background-color: #ecf0f1; margin-left: -20px; margin-right: -20px; padding-left: 20px; padding-top: 10px; padding-bottom: 10px }
.input-container .footer { height: 55px; display: flex; justify-content: space-between; padding: 0px 20px 15px 20px; margin-top: 7.5px }
::placeholder { color: rgba(80,80,80,0.3); opacity: 1 }
:-ms-input-placeholder { color: rgba(80,80,80,0.3) }
::-ms-input-placeholder { color: rgba(80,80,80,0.3) }

/* ========================================= */
/* ======= "TABLE & GRID LOADING" STYLE ==== */
/* ========================================= */
.grid { width: 100%; padding-top: 30px; }
.grid-list { width: 100%; overflow-y: scroll; height: calc(70vh - 50px); -ms-overflow-style: none; scrollbar-width: none }
.grid-list::-webkit-scrollbar { display: none; }
.grid-list .contents { display: flex; justify-content: space-between; align-items: center; padding: 10px 10px; background-color: rgba(255,255,255,0.4); border-radius: 10px; margin-bottom: 10px; }
.grid-list .empty { display: flex; align-items: center; padding: 10px 15px; background-color: rgba(243, 156, 18, 0.3); border-radius: 10px; margin-bottom: 10px; color: #e67e22; font-size: 16px; }
.grid-list .contents:hover { background-color: #fff; cursor: pointer; }
.grid-list .contents .items { font-size: 10pt; color: rgba(80,80,80,1.0); width: 100%; }
.grid-list .contents .items.left { text-align: left; }
.grid-list .contents .items.right { text-align: right; }
.grid-list .contents .items.flex { display: flex; align-items: center; }
.grid-list .contents .items .image { width: 42px; height: 42px; object-fit: cover; border-radius: 7px; margin-right: 15px }
.grid-list .contents .items .image-empty { width: 42px; height: 42px; border-radius: 7px; margin-right: 15px; background-color: #EFEFEF; display: flex; align-items: center; justify-content: center; font-size: 12px; font-weight: bold; }
.grid-list .contents .items .title { font-weight: 900 }
.grid-list .contents .items .subtitle { font-size: 9pt; color: rgba(80,80,80,0.7) }

/* ========================================= */
/* ======= "COMPONENT LOADING" STYLE ======= */
/* ========================================= */
.ComponentLoading { z-index: 9999; top: 0; left: 0; position: absolute; width: 100%; height: 100vh; background-color: rgba(0,0,0,0.8); text-align: center; padding: 48vh 0 }
.ComponentLoading-container { background-color: #D400B8; color: #fff; font-size: 16px; padding: 10px 15px; border-radius: 50px; display: inline }
.ComponentLoading-img { height: 24px; margin-right: 10px; }

/* ========================================= */
/* ======= "COMPONENT FORM INPUT" STYLE ==== */
/* ========================================= */
.ModalInput { text-align: left; }
.ModalInput .label { font-size: 11pt; color: rgba(80,80,80,0.7); }
.ModalInput .input { font-size: 12pt; color: rgba(80,80,80,1.0); width: 100%; border: none; border-bottom: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding-bottom: 7.5px }
.ModalInput .input:focus { outline: none; border-bottom: solid 1px #D400B8 }
.ModalInput .required { display: flex; justify-content: space-between; font-size: 12px; margin-top: 5px;}
.ModalInput .required .true { color: #e74c3c; }
.ModalInput .required .false { color: #3498db; }
.ModalInput .required .length { color: #505050; }

/* ========================================= */
/* ======= "COMPONENT LOGOUT" STYLE ======== */
/* ========================================= */
.ComponentLogout { z-index: 9999; top: 0; left: 0; position: absolute; width: 100vw; height: 100vh; background-color: rgba(0,0,0,0.8); text-align: center; padding-top: 35vh; padding-left: 35vw; }
.ComponentLogout-container { background-color: #fff; width: 30vw; height: 220px; border-radius: 20px; overflow: hidden; animation: fadeInAnimation ease 2s; animation-iteration-count: 1; animation-fill-mode: forwards }
.ComponentLogout-toolbar { background-color: #e74c3c; color: #fff; font-size: 18px; font-weight: bold; height: 50px; padding-top: 10px }
.ComponentLogout-content { padding: 35px 70px; color: #505050; font-size: 16px; height: 115px }
.ComponentLogout-footer { height: 55px; padding-top: 0px }

/* ========================================= */
/* ======= "COMPONENT DIALOG ALERT" STYLE == */
/* ========================================= */
.ComponentDialogAlert { z-index: 9999; top: 0; left: 0; position: absolute; width: 100vw; height: 100vh; background-color: rgba(0,0,0,0.8); text-align: center; padding-top: 35vh; padding-left: 35vw; }
.ComponentDialogAlert.hide { display: none; }
.ComponentDialogAlert-container { background-color: #fff; width: 30vw; height: 220px; border-radius: 20px; overflow: hidden; animation: fadeInAnimation ease 2s; animation-iteration-count: 1; animation-fill-mode: forwards }
.ComponentDialogAlert-toolbar { background-color: #e74c3c; color: #fff; font-size: 18px; font-weight: bold; height: 50px; padding-top: 10px }
.ComponentDialogAlert-content { padding: 35px 70px; color: #505050; font-size: 16px; height: 115px }
.ComponentDialogAlert-footer { height: 55px; padding-top: 0px }

/* ============================================ */
/* ======= "PAYMENT" COMPONENT STYLE ========== */
/* ============================================ */
.PaymentModal { display: flex; align-items: flex-start }
.PaymentModal .tab { width: 45% }
.PaymentModal .tab .items { cursor: pointer; color: rgba(80,80,80,0.3); text-align: left; margin-bottom: 20px; display: flex; justify-content: space-between; align-items: center }
.PaymentModal .tab .items .selected { width: 7px; height: 40px; border-top-left-radius: 7px; border-bottom-left-radius: 7px; background-color: #FFF }
.PaymentModal .tab .items .title { flex: 1; padding-left: 20px; }
.PaymentModal .tab .items .selected.active { background-color: #D400B8; }
.PaymentModal .tab .items .image { width: 36px; height: 36px; object-fit: contain; opacity: 0.4; }
.PaymentModal .tab .items .image.active { opacity: 1; }
.PaymentModal .tab .items:hover { color: #505050 }
.PaymentModal .tab .items.active { color: #505050; font-weight: bold; }
.PaymentModal .summary { width: 55%; padding-left: 15px; text-align: left; border-left: solid 1px rgba(0,0,0,0.05); }
.PaymentModal .summary .payment-methods .lists { height:max-content; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll; height: 50vh; }
.PaymentModal .summary .payment-methods .lists::-webkit-scrollbar { display: none; }
.PaymentModal .summary .payment-methods .lists .items { margin-bottom: 12px; background-color: #F9F9F9; padding: 10px 12px; border-radius: 7.5px; border: solid 1px #F9F9F9; display: flex; align-items: center; color: rgba(80,80,80,0.3); cursor: pointer; }
.PaymentModal .summary .payment-methods .lists .items:hover { border: solid 1px #E2E2E2; }
.PaymentModal .summary .payment-methods .lists .items.active { border: solid 1px #D400B8; color: rgba(80,80,80,1.0); }
.PaymentModal .summary .payment-methods .lists .items .image { width: 50px; height: 50px; object-fit: contain; opacity: 0.4; }
.PaymentModal .summary .payment-methods .lists .items .image.active { opacity: 1 }
.PaymentModal .summary .payment-methods .lists .items .desc { padding: 0 15px; flex: 1; }
.PaymentModal .summary .payment-methods .lists .items .desc .title { font-size: 15px; font-weight: bold; }
.PaymentModal .summary .payment-methods .lists .items .desc .subtitle { font-size: 12px; }
.PaymentModal .summary .payment-methods .lists .items i.active { color: #D400B8; }
.PaymentModal .summary .payment-methods .form { padding-top: 15px; display: grid; }
.PaymentModal .summary .payment-methods .form .input { border: none; border-bottom: solid 1px rgba(0,0,0,0.05); padding: 10px 0; font-size: 16px; font-weight: bold; margin-bottom: 5px; }
.PaymentModal .summary .payment-methods .form .input:focus { outline: none; border-bottom: solid 1px #D400B8 }
.PaymentModal .summary .info { display: flex; justify-content: space-between; }
.PaymentModal .summary .payment { margin-top: 20px; }
.PaymentModal .summary .payment .form { display: flex; align-items: center; }
.PaymentModal .summary .payment .form .input { flex: 1; margin-right: 15px; border: none; border-bottom: solid 1px rgba(0,0,0,0.05); padding: 10px 0; font-size: 16px; font-weight: bold; }
.PaymentModal .summary .payment .form .input:focus { outline: none; border-bottom: solid 1px #D400B8 }
.PaymentModal .summary .payment .form .button { background-color: #505050; padding: 5px 10px; border-radius: 5px; color: #fff; font-size: 15px; cursor: pointer; }
.PaymentModal .summary .payment .form .button:hover { background-color: #000 }
.PaymentModal .summary .payment .currency { margin-top: 20px; columns: 100px 2; -webkit-columns: 100px 2; -moz-columns: 100px 2; padding-bottom: 10px; }
.PaymentModal .summary .payment .return { display: flex; justify-content: space-between; align-items: center; font-size: 16px; font-weight: bold; }

/* ============================================ */
/* ======= "HISTORY" COMPONENT STYLE ========== */
/* ============================================ */
.HistoryModal { height: 60vh; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll }
.HistoryModal::-webkit-scrollbar { display: none; }
.HistoryModal .items { text-align: left; border-bottom: solid 1px rgba(0,0,0,0.05); padding-bottom: 12px }
.HistoryModal .items .title { font-size: 16px; font-weight: bold; color: #505050 }
.HistoryModal .items .date { font-size: 12px; color: #D400B8 }
.HistoryModal .items .desc { font-size: 14px; color: #505050 }
.HistoryModal .lists { text-align: left; border-bottom: solid 1px rgba(0,0,0,0.05); padding-bottom: 12px }
.HistoryModal .lists .title { font-size: 14px; color: #505050 }
.HistoryModal .lists .value { font-size: 15px; color: #505050; font-weight: bold }
.HistoryModal .lists .desc { font-size: 14px; color: #505050 }

/* ============================================ */
/* ======= "MODAL FORM" COMPONENT STYLE ======= */
/* ============================================ */
.ModalForm.flex { display: flex; }
.ModalForm .left { flex: 0.33; border-right: solid 1px rgba(0,0,0,0.05); padding-right: 15px; height: 78vh; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll }
.ModalForm .left::-webkit-scrollbar { display: none; }
.ModalForm .center { flex: 0.33; padding: 0 15px; height: 78vh; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll; }
.ModalForm .center::-webkit-scrollbar { display: none; }
.ModalForm .right { flex: 0.33; border-left: solid 1px rgba(0,0,0,0.05); padding-left: 15px; height: 78vh; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll; }
.ModalForm .right::-webkit-scrollbar { display: none; }
.ModalForm .label { font-size: 11pt; color: rgba(80,80,80,0.7); text-align: left; }
.ModalForm .required { font-size: 8pt; color: rgba(80,80,80,0.7); margin-top: 5px; display: flex; justify-content: space-between }
.ModalForm .required .true { color: #e74c3c; }
.ModalForm .required .false { color: #1ABC9C; }
.ModalForm .input { font-size: 12pt; color: rgba(80,80,80,1.0); width: 100%; border: none; border-bottom: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding-bottom: 7.5px }
.ModalForm .input:focus { outline: none; border-bottom: solid 1px #D400B8 }
.ModalForm .section { text-align: left; font-weight: bold; color: #505050; font-size: 16px; margin-top: 20px; }
.ModalForm .note { color: rgba(80,80,80,0.6); font-size: 12px; margin-top: 15px; text-align: left; }

/* ================================= */
/* ======= "ANIMATION" STYLE ======= */
/* ================================= */
@keyframes fadeInAnimation {
  0% { opacity: 0.1 }
  100% { opacity: 1 }
}

/* ================================== */
/* ======= "RESPONSIVE" STYLE ======= */
/* ================================== */
@media screen and (max-width:450px) {
  html, body { width: 100%; position: relative; overflow-x: hidden; }
  .width_full {
    width: 100%
  }
}


/* laundru */
@media screen and (max-width:1024px) {
  .Login-input-container { width: 100%; padding-left: 12vw; padding-right: 12vw }

  .modal-50w { max-width: 100% }
  .modal-60w { max-width: 100% }
  .modal-80w { max-width: 100% }
  .modal-90w { max-width: 100% }
  .modal-70h { height: 84vh }
  .modal-75h { height: 84vh }
  .modal-80h { height: 84vh }
  .modal-lg { max-width: 100% }
  /* .modal-body {  } */

  /* ======= "SEARCH CONTAINER" VIEW STYLE ====== */
  .search-container { margin-top: 15px }
  .search-container .center { width: 70%; }
  .search-container .right { display: none;  }
  .input-container { width: 100vw; right: 100vw }

  /* ======= "MY CONTAINERS" STYLE ======= */
  .MyContainers { display: block; height: 100vh }
  .MyContainers-right { margin-left: 0px; background-color: #F6F6F6; padding: 60px 0px 0px 0px; width: 100%; height: 100vh; right: 0; z-index: 0; border-top-left-radius: 0px; border-bottom-left-radius: 0px }
  .MyContainers-right-toolbar { display: none }
  .MyContainers-right-contents { height: calc(100vh - 60px) }

  /* ======= "COMPONENT LOGOUT" STYLE ======= */
  .ComponentLogout { padding: 0 }
  .ComponentLogout-container { width: 100vw; position: absolute; bottom: 0; overflow: hidden; border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; }
  .ComponentDialogAlert { padding: 0 }
  .ComponentDialogAlert-container { width: 100vw; position: absolute; bottom: 0; overflow: hidden; border-top-left-radius: 20px; border-top-right-radius: 20px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px; }

  /* ======= "ACCOUNT" VIEW STYLE =============== */
  .Account { padding: 0; display: contents; }
  .Account .profile { display: flex; flex-direction: row; background-color: #FFF; padding: 20px; align-items: center }
  .Account .profile .right { padding-left: 20px; width: 70%; }
  .Account .profile .right .name { margin-top: 0px; font-size: 16px; }
  .Account .profile .right .email { font-size: 12px }
  .Account .profile .right .mobilephone { font-size: 12px; }
  .Account .profile .right .address { display: none; }
  .Account .profile .right .role { font-size: 12px; font-weight: bold; color: #D400B8; margin-top: 5px; background-color: transparent; text-align: left; padding: 0; margin-bottom: 0px }
  .Account .profile .left { border-radius: 10px; width: 100px; height: 100px; position: relative }
  .Account .profile .left .button { position: absolute; top: 24px; right: 33px; font-size: 32px; cursor: pointer; }
  .Account .profile .left .avatar { width: 100px; height: 100px; border-radius: 10px; object-fit: cover; align-items: center; justify-content: center }
  .Account-menu { position: absolute; top: 210px; width: 100vw; padding-left: 0; }
  .Account-menu .menu { cursor: pointer; background-color: #fff; margin-bottom: 0px; width: 100vw; padding-top: 15px; padding-bottom: 15px; border:none; border-bottom: solid 1px rgba(0,0,0,0.05); padding-left: 20px; color: #505050; display: flex; justify-content: space-between; padding-right: 20px; align-items: center }
  .Account-menu .menu a { cursor: pointer; width: 100vw; border:none; color: #505050; display: flex; justify-content: space-between; align-items: center }
  .Account-menu .menu:hover { background-color: #fff; color: #505050; border: none; border-bottom: solid 1px rgba(0,0,0,0.05) }
  .Account-menu .menu a:hover { background-color: #fff; color: #505050; border: none }
  .Account-menu .menu .icon { display: block }
  .Account-menu .menu .version { margin-left: 0 }
  
  /* ======= "GRID" VIEW STYLE ================== */
  .grid { padding-top: 15px }
  .grid-list { height: 72vh }
  .grid-list .contents { display: block; padding: 15px; background-color: #fff; }
  .grid-list .contents:hover { background-color: #fff; }

  /* ======= "TAB CONTAINER" VIEW STYLE ========= */
  .tab-container { background-color: #fff; width: 100vw; padding-left: 20px; margin-left: -20px; text-align: center; padding-top: 0; padding-bottom: 0; overflow-y: scroll; justify-content: center }
  .tab-container .item { display: flex; align-items: center; height: 55px; padding: 0; font-size: 14px; border-bottom:solid 2px #fff }
  .tab-container .item.active { border: none; border-bottom:solid 2px #2980B9; padding: 0; color: #2980B9; }

  /* ======= "TRANSACTION MODAL" COMPONENT STYLE ========= */
  .TransactionModal { display: block; -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll; padding-bottom: 20px; }
  .TransactionModal::-webkit-scrollbar { display: none; }
  .TransactionModal .left { width: 100%; border: none; padding: 0; border-bottom: solid 1px rgba(0,0,0,0.05) }
  .TransactionModal .left .user .transaction { text-align: right; padding: 0; }
  .TransactionModal .center { width: 100%; margin-top: 0px; padding: 20px 0 0 0; border-right: none; }
  .TransactionModal .center .product .price { text-align: right; padding: 0; }
  .TransactionModal .right { width: 100%; margin-top: 5px; padding: 20px 0 0 0; border-top: solid 1px rgba(0,0,0,0.05); }
  .TransactionModal .right .status { margin-top: 15px; }
  .TransactionModalFooter { justify-content: space-between; }

  /* ======= "PAYMENT MODAL" COMPONENT STYLE ========= */
  .PaymentModal { display: block }
  .PaymentModal .tab { width: 100%; border-right: none; border-bottom: solid 1px rgba(0,0,0,0.05); display: flex }
  .PaymentModal .tab .items { padding-bottom: 10px; margin: 0; display: flex; justify-content: space-between; align-items: center; margin-right: 15px; }
  .PaymentModal .tab .items .selected { display: none }
  .PaymentModal .tab .items .title { padding: 0 }
  .PaymentModal .tab .items .title .desc { display: none }
  .PaymentModal .tab .items .image { display: none }
  .PaymentModal .tab .items:hover { color: #D400B8 }
  .PaymentModal .tab .items.active { color: #D400B8; font-weight: bold; border-bottom: solid 2px #D400B8 }
  .PaymentModal .summary { margin-top: 15px; width: 100%; padding-left: 0px; border: none; }
  .PaymentModal .summary .payment .currency { border-bottom: solid 1px rgba(0,0,0,0.05) }
  .PaymentModal .summary .payment .return { padding-top: 20px }

  /* ======= "FORM MODAL" COMPONENT STYLE ========= */
  .ModalForm { -ms-overflow-style: none !important; scrollbar-width: none; overflow-y: scroll; }
  .ModalForm::-webkit-scrollbar { display: none; }
  .ModalForm.flex { display: block; height: 100%; margin-left: -15px; margin-right: -15px; }
  .ModalForm .left { flex: 1; border: none; height: fit-content; padding: 0 15px; overflow: hidden; }
  .ModalForm .center { flex: 1; height: fit-content; padding: 0 15px; overflow: hidden; }
  .ModalForm .right { flex: 1; border: none; height: fit-content; padding: 0 15px; overflow: hidden; }
  .ModalForm .section { background-color: #ecf0f1; margin-left: -20px; margin-right: -20px; color: #505050; font-size: 14px; padding: 10px 15px; }

  /* ======= "POS" VIEW STYLE ========= */
  .Pos { padding: 0; display: block }
  .Pos .left { display: none; }
  .Pos .right { width: 100%; }
  .Pos .right .stores { display: flex; }
  .Pos .right .carts .header { padding-top: 0px; padding-bottom: 5px; }
  .Pos .right .carts .lists { height: 37vh; margin-top: 7px; }
  .Pos .right .carts .action { display: flex; }
  .Pos .right .carts .action .link {
    border: solid 1px #999;
    color: #505050;
    font-size: 15px;
    border-radius: 20px;
    padding: 2px 10px;
    cursor: pointer;
  }
  .Pos .right .carts .action .link.blue {
    border: solid 1px #D400B8;
    color: #D400B8;
  }

  .modal-70h .scollable { height: 95%; }
}

@media (min-width: 576px){
  .modal-dialog {
      max-width: 450px;
      margin: 1.75rem auto;
  }
}

/* Ripple effect */
.ripple {
  background-position: center !important;
  transition: background 1s !important;
}

.ripple:active {
  background-color: rgba(255,255,255,0.2) !important;
  background-size: 100% !important;
  transition: background 0s !important;
}

.button-rounded{
  padding: 5px 15px; 
  border-radius: 50px;
}

#turn{
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #D400B8;
}

@media screen and (max-width:767px) {
  #turn { display:none; }
  #root { display:block; }
}
  
@media screen and (min-width:320px) and (max-width:767px) and (orientation:landscape) {
  #turn { display:flex !important; }
  #root { display:none; }
}

@media screen and (max-width:1024px){
  #turn { display:none; }
  #root { display:block; }
}
@media screen and (min-width:768px) and (max-width:1024px) and (orientation:landscape){
  #turn { display:flex !important; }
  #root { display:none; }
}
@media screen and (min-width:1024px){
  #turn { display:none; }
  #root { display:block; }
}

.bg-themes::before{
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-image: var(--background-themes);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}


.background-theme-default{
  background-color: #D400B8;
}
.background-light-theme-default{
  background-color: #FA37E1;
}
.background-dark-theme-default{
  background-color: #950082;
}
.gradient-theme-default{
  background: linear-gradient(#950082, #FA37E1)
}
.color-theme-default{
  color: #D400B8;
}


.background-theme-dark{
  background-color: #333333;
}
.background-light-theme-dark{
  background-color: #666666;
}
.background-dark-theme-dark{
  background-color: #222222;
}
.gradient-theme-dark{
  background: linear-gradient(#222222, #666666)
}
.color-theme-dark{
  color: #333333;
}


.background-theme-amethyst{
  background-color: #9b59b6;
}
.background-light-theme-amethyst{
  background-color: #e056fd;
}
.background-dark-theme-amethyst{
  background-color: #8e44ad;
}
.gradient-theme-amethyst{
  background: linear-gradient(#8e44ad, #e056fd)
}
.color-theme-amethyst{
  color: #9b59b6;
}


.background-theme-green_sea{
  background-color: #10ac84;
}
.background-light-theme-green_sea{
  background-color: #1dd1a1;
}
.background-dark-theme-green_sea{
  background-color: #16a085;
}
.gradient-theme-green_sea{
  background: linear-gradient(#16a085, #1dd1a1)
}
.color-theme-green_sea{
  color: #10ac84;
}



.background-theme-belize_hole{
  background-color: #3498db;
}
.background-light-theme-belize_hole{
  background-color: #00a8ff;
}
.background-dark-theme-belize_hole{
  background-color: #2980b9;
}
.gradient-theme-belize_hole{
  background: linear-gradient(#2980b9, #00a8ff)
}
.color-theme-belize_hole{
  color: #3498db;
}



.background-theme-amour{
  background-color: #ee5253;
}
.background-light-theme-amour{
  background-color: #eb4d4b;
}
.background-dark-theme-amour{
  background-color: #c0392b;
}
.gradient-theme-amour{
  background: linear-gradient(#c0392b, #eb4d4b)
}
.color-theme-amour{
  color: #ee5253;
}

.modal-bt-sheet .modal-dialog{
  display: flex;
  align-items: end;
  min-height: 100%;
  max-width: 480px;
  margin: 0 auto;
}