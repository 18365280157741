.modal-theme .modal-header{
    display: block;
    background-color: #fff;
    border-radius: 15px 15px 0 0;
    padding: 15px;
}

.modal-theme .modal-body{
    text-align: left;
    padding: 0;
}

.modal-theme .modal-content {
    background-color: #D400B8;
}

.theme-item{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    color: rgba(255,255,255, 1);
    padding: 15px;
    cursor: pointer;
}

.theme-item-skeleton{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    color: rgba(255,255,255, 1);
    padding: 15px;
}

.theme-item:not(:last-child),
.theme-item-skeleton:not(:last-child){
    border-bottom: 1px solid rgba(255,255,255, 0.2);
}

.theme-item:last-child{
    border-radius: 0 0 15px 15px;
}