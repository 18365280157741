.modal-music-playlist .modal-header{
    display: block;
    /* background-color: #950082; */
    border-radius: 15px 15px 0 0;
    padding: 15px;
}

.modal-music-playlist .modal-body{
    text-align: left;
    padding: 0;
}

.modal-music-playlist .modal-content > div {
    /* background-color: #D400B8; */
    border-radius: 15px;
}

.playlist-item{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    color: rgba(255,255,255, 1);
    padding: 15px;
    cursor: pointer;
}

.playlist-item-skeleton{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    color: rgba(255,255,255, 1);
    padding: 15px;
}

.playlist-item:not(:last-child),
.playlist-item-skeleton:not(:last-child){
    border-bottom: 1px solid rgba(255,255,255, 0.2);
}

.playlist-item:last-child{
    border-radius: 0 0 15px 15px;
}