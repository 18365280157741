.modal-feedback .modal-header{
  display: block;
  border-radius: 15px 15px 0 0;
  padding: 15px;
}

.modal-feedback .modal-body{
  text-align: left;
  padding: 0;
}

.modal-feedback .modal-content > div {
  border-radius: 15px;
}

.modal-feedback .label { font-size: 11pt; color: rgba(80,80,80,0.7); margin-top: 20px }
.modal-feedback .required { font-size: 9pt; color: rgba(80,80,80,0.7); margin-top: 5px; display: flex; justify-content: space-between }
.modal-feedback .required .true { color: #e74c3c; }
.modal-feedback .required .false { color: #1ABC9C; }
.modal-feedback .input { font-size: 12pt; color: #fff; width: 100%; border: none; border-bottom: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding-bottom: 7.5px }
.modal-feedback .input:focus { outline: none; border-bottom: solid 1px #fff !important }
.modal-feedback .select { font-size: 12pt; color: #fff; width: 100%; border: none; border-bottom: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding-bottom: 7.5px }
.modal-feedback .select:focus { outline: none; border-bottom: solid 1px #fff !important }
.modal-feedback .textarea { font-size: 12pt; color: #fff; width: 100%; border: none; border-bottom: solid 1px rgba(0,0,0,0.1); margin-top: 7.5px; padding-bottom: 7.5px; resize: none;}
.modal-feedback .textarea:focus { outline: none; border-bottom: solid 1px #fff !important }