.navbar { display: none }
.navbar-toggle { display: none }
.menu-bars { display: none }
.nav-menu { background-color: #0098D9; width: 290px; height: 100vh; position: fixed; top: 0 }
.nav-text { display: flex; justify-content: start; align-items: center; list-style: none; height: 40px; position: relative }
.nav-text-active { color: #fff !important }
.nav-indicator-active { height: 40px; width: 5px; position: absolute; right: 30px; background-color: aliceblue; border-top-left-radius: 10px; border-bottom-left-radius: 10px }
.nav-text a { text-decoration: none; color: rgba(255, 255, 255, 0.45); font-size: 14px; width: 88%; height: 100%; display: flex; align-items: center }
.nav-text a:hover { color: #fff }
.nav-section { list-style: none; padding: 12px 0; color: rgba(255, 255, 255, 0.45); font-size: 14px; font-weight: 900; border-bottom: solid 1px rgba(255, 255, 255, 0.2); border-top: solid 1px rgba(255, 255, 255, 0.2); margin-top: 7.5px; margin-bottom: 10px }
.nav-logout { display: flex; justify-content: start; align-items: center; list-style: none; height: 40px; color: rgba(255, 255, 255, 0.45); font-size: 14px; cursor: pointer }
.nav-logout:hover { color: #fff }
.nav-menu-items { width: 100%; margin-top: 20px; overflow-x: scroll; height: 100vh; padding-bottom: 220px }
.nav-icon { width: 30px; font-size: 14px }
.nav-account { padding-top: 30px; padding-left: 30px; padding-right: 50px }
.nav-account-avatar { width: 100px; height: 100px; object-fit: cover; border-radius: 15px }
.nav-account-name { margin-top: 15px; color: #fff; font-weight: bold; font-size: 18px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical }
.nav-account-email { color: #fff; font-size: 12px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical }

@media screen and (max-width:1024px) {
    .navbar { background-color: #0098D9; height: 60px; display: flex; justify-content: start; align-items: center; width: 100vw; position: fixed }
    .navbar-toggle { background-color: #0098D9; width: 100%; height: 60px; display: flex; justify-content: start; align-items: center }
    .menu-container { display: contents }
    .menu-bars-menu { margin-left: 20px; display: block; font-size: 25px; color: rgba(255, 255, 255, 0.45) }
    .menu-bars-close { margin-left: 303px; display: block; font-size: 20px; color: rgba(255, 255, 255, 0.45) }
    .menu-bars-menu:hover { color: #fff }
    .menu-bars-close:hover { color: #fff }
    .menu-bars-title { color: rgba(255, 255, 255, 0.50); font-weight: 900; font-size: 18px; margin-left: 20px; }
    .nav-menu { left: -100%; transition: 850ms; z-index: 1 }
    .nav-menu.active { left: 0; transition: 350ms; z-index: 1 }
    .nav-indicator-active { right: 0px }
}