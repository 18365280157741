.modal-datepicker .modal-dialog {
    display: flex;
    align-items: end;
    min-height: 100%;
    max-width: 480px;
    margin: 0 auto;
  }
  
  .modal-datepicker .modal-header{
    display: block;
  }
  
  .modal-datepicker .modal-content > div {
    /* background-color: #D400B8; */
    border-radius: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px;
  }